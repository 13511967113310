<template>
  <div
    v-if="!props.params.node?.group && props.params.node?.rowPinned !== 'bottom'"
    class="elv-financials-cell-category"
  >
    <div v-if="props.params.data?.journalActivityList?.length" class="elv-financials-cell-categorized-list">
      <div class="elv-financials-cell-categorized">
        <SvgIcon name="report-checkmark" width="14" height="14" fill="#50970B" />{{
          props.params.data?.journalActivityList?.[0]?.journalType?.name
        }}
      </div>
      <SvgIcon
        v-if="props.params.data?.journalActivityList?.length > 1"
        class="elv-financials-cell-categorize__more"
        name="more-dian"
        width="16"
        height="16"
        fill="#838D95"
      />
    </div>
    <div v-else class="elv-financials-cell-category__none">{{ t('title.transactionCategorize') }}</div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
</script>
<style lang="scss">
.elv-financials-cell-category {
  display: flex;
  align-items: center;

  .elv-financials-cell-category__none {
    color: #2f63eb;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }

  .elv-financials-cell-categorized-list {
    display: flex;
    align-items: center;
    gap: 8px;

    .elv-financials-cell-categorize__more {
      transform: rotate(90deg);
    }
  }

  .elv-financials-cell-categorized {
    display: flex;
    height: 22px;
    padding: 0px 8px;
    align-items: center;
    border-radius: 14px;
    background: #f1f8ea;
    color: #41780c;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;

    svg {
      margin-right: 4px;
    }
  }
}
</style>
