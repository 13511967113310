<template>
  <div class="elv-financials-cell-topBottom-main">
    <div class="elv-financials-cell-topBottom-conent">
      <template v-if="props.params?.value?.cellName === 'Amount (In)'">
        <div
          v-if="props.params?.data?.amountInToken === null || props.params?.data?.direction === 'OUT'"
          class="elv-financials-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__top-header"
            :title="`${`${
              entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
            }`}${props.params?.data?.fieldValues?.amountInFC}`"
          >
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.fieldValues?.amountInFC, 2) }}
          </div>
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('common.sum') }}
          </div>
        </template>
        <template v-else>
          <template v-if="props.params?.value?.tableType !== 'transactions'">
            <div
              class="elv-financials-cell-topBottom-content__top"
              :title="`${`${
                entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
              }`}${amountInValue.value}`"
            >
              {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              }}{{ amountInValue.formatValue }}
            </div>
            <div class="elv-financials-cell-topBottom-content__bottom" :title="amountInTokenTitleValue">
              {{ amountInTokenValue }}
              {{
                props.params?.data?.currency?.showSymbol
                  ? props.params?.data?.currency?.showSymbol
                  : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)
              }}
            </div>
          </template>
          <template v-else>
            <div class="elv-financials-cell-topBottom-content__top" :title="amountInTokenTitleValue">
              <span
                >{{ amountInTokenValue }}
                {{
                  props.params?.data?.currency?.showSymbol
                    ? props.params?.data?.currency?.showSymbol
                    : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)
                }}</span
              >
              <span v-if="props.params?.data?.amountFC" class="elv-financials-cell-date-time-main-bottom__txHash">{{
                formatNumberToSignificantDigits(
                  props.params?.data?.amountFC,
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              }}</span>
            </div>
          </template>
        </template>
      </template>
      <template v-else-if="props.params?.value?.cellName === 'Amount (Out)'">
        <div
          v-if="props.params?.data?.amountOutToken === null || props.params?.data?.direction === 'IN'"
          class="elv-financials-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__top-header"
            :title="`${`${
              entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
            }`}${props.params?.data?.fieldValues?.amountOutFC}`"
          >
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.fieldValues?.amountOutFC, 2) }}
          </div>
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('common.sum') }}
          </div>
        </template>
        <template v-else>
          <template v-if="props.params?.value?.tableType !== 'transactions'">
            <div
              class="elv-financials-cell-topBottom-content__top"
              :title="`${`${
                entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
              }`}${amountOutValue.value}`"
            >
              {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              }}{{ amountOutValue?.formatValue }}
            </div>
            <div class="elv-financials-cell-topBottom-content__bottom" :title="amountOutTokenTitleValue">
              {{ amountOutTokenValue }}
              {{
                props.params?.data?.currency?.showSymbol
                  ? props.params?.data?.currency?.showSymbol
                  : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)
              }}
            </div>
          </template>
          <template v-else>
            <div class="elv-financials-cell-topBottom-content__top" :title="amountOutTokenTitleValue">
              <span
                >{{ amountOutTokenValue }}
                {{
                  props.params?.data?.currency?.showSymbol
                    ? props.params?.data?.currency?.showSymbol
                    : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)
                }}</span
              >
              <span v-if="props.params?.data?.amountFC" class="elv-financials-cell-date-time-main-bottom__txHash">{{
                formatNumberToSignificantDigits(
                  props.params?.data?.amountFC,
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              }}</span>
            </div>
          </template>
        </template>
      </template>
      <template v-else-if="props.params?.value?.cellName === 'Amount'">
        <div
          v-if="
            (props.params?.data?.amount === null && props.params?.data?.amountFC === null) ||
            (props.params?.data?.amount === undefined && props.params?.data?.amountFC === undefined)
          "
          class="elv-financials-cell-topBottom-main__empty"
        >
          -
        </div>
        <template v-else>
          <div
            class="elv-financials-cell-topBottom-content__top"
            :title="`${fieldValueFormat(
              props.params?.data?.amountFC,
              {
                price: true,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}`"
          >
            {{
              formatNumberToSignificantDigits(
                props.params?.data?.amountFC,
                2,
                `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              )
            }}
          </div>
          <div
            v-if="
              props.params?.data?.chartOfAccount?.currencyTypes?.[0] !== 'REPORTING' &&
              props.params?.data?.amount !== null
            "
            class="elv-financials-cell-topBottom-content__bottom"
            :title="`${formatNumber(props.params?.data?.amount, 20)} ${
              props.params?.data?.currency?.showSymbol
                ? props.params?.data?.currency?.showSymbol
                : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)
            }`"
          >
            {{
              formatNumberToSignificantDigits(
                props.params?.data?.amount !== null ? props.params?.data?.amount : 0,
                2,
                '',
                false
              )
            }}
            {{
              props.params?.data?.currency?.showSymbol
                ? props.params?.data?.currency?.showSymbol
                : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)
            }}
          </div>
          <div v-else>-</div>
        </template>
      </template>
      <template v-else-if="props.params?.value?.cellName === 'Unrealized P&L'">
        <template v-if="props.params.node?.rowPinned === 'top'">
          <div
            v-if="props.params?.data?.fieldValues?.unrealizedAmountFCTotal === null"
            class="elv-financials-cell-topBottom-content__top-header"
          >
            -
          </div>
          <div
            v-else
            class="elv-financials-cell-topBottom-content__top-header"
            :title="`${fieldValueFormat(
              props.params?.data?.fieldValues?.unrealizedAmountFCTotal,
              {
                price: true,
                keepPoint: true,
                symbol: `${
                  entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )}`"
          >
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.fieldValues?.unrealizedAmountFCTotal, 2) }}
          </div>
        </template>
        <template v-else>
          <template v-if="props.params?.node?.group">
            <div
              class="elv-financials-cell-topBottom-content__top"
              :title="`${fieldValueFormat(
                props.params?.data?.unrealizedAmountFCTotal,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )}`"
            >
              {{
                formatNumberToSignificantDigits(
                  props.params?.data?.unrealizedAmountFCTotal,
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              }}
            </div>
          </template>
          <template v-else>
            <div
              class="elv-financials-cell-topBottom-content__top"
              :title="`${formatNumber(props.params?.data?.unrealizedAmount, 20)}`"
            >
              {{ formatNumberToSignificantDigits(props.params?.data?.unrealizedAmount, 2, '', false) }}
            </div>
            <div
              v-if="props.params?.data?.unrealizedAmountFC !== null"
              class="elv-financials-cell-topBottom-content__bottom"
              :title="`${fieldValueFormat(
                props.params?.data?.unrealizedAmountFC,
                {
                  price: true,
                  keepPoint: true,
                  symbol: `${
                    entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                  }`
                },
                'NUMBER'
              )}`"
            >
              {{
                formatNumberToSignificantDigits(
                  props.params?.data?.unrealizedAmountFC !== null ? props.params?.data?.unrealizedAmountFC : 0,
                  2,
                  `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                )
              }}
            </div>
            <div v-else>-</div>
          </template>
        </template>
      </template>
      <template v-else-if="props.params?.value?.cellName === 'Debit'">
        <div v-if="props.params?.data?.debitFC === null" class="elv-financials-cell-topBottom-main__empty">-</div>
        <template v-else-if="props.params?.node?.group && props.params?.data?.debitFC">
          <div class="elv-financials-cell-topBottom-content__top-header">
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.debitFC, 2) }}
          </div>
        </template>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('report.totalDebit') }}
          </div>
          <div class="elv-financials-cell-topBottom-content__top-header">
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.fieldValues?.debitFC, 2) }}
          </div>
        </template>
        <template v-else>
          <div class="elv-financials-cell-topBottom-content__top">
            {{ formatNumber(props.params?.data?.debitFC, 2) }}
          </div>
          <div class="elv-financials-cell-topBottom-content__bottom">
            {{ props.params?.data?.debitAmount < Math.pow(10, -6) ? '0' : props.params?.data?.debitAmount }}
            {{ props.params?.data?.currency?.showSymbol }}
          </div>
        </template>
      </template>
      <template v-if="props.params?.value?.cellName === 'Credit'">
        <div v-if="props.params?.data?.creditFC === null" class="elv-financials-cell-topBottom-main__empty">-</div>
        <template v-else-if="props.params?.node?.group && props.params?.data?.creditFC">
          <div class="elv-financials-cell-topBottom-content__top-header">
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.creditFC, 2) }}
            <!-- {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.debitFC, 2) }} -->
          </div>
        </template>
        <template v-else-if="props.params.node?.rowPinned === 'bottom'">
          <div
            class="elv-financials-cell-topBottom-content__bottom elv-financials-cell-topBottom-content__bottom-color-zoom"
          >
            {{ t('report.totalCredit') }}
          </div>
          <div class="elv-financials-cell-topBottom-content__top-header">
            {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.fieldValues?.creditFC, 2) }}
            <!-- {{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
            }}{{ formatNumber(props.params?.data?.fieldValues?.debitFC, 2) }} -->
          </div>
        </template>
        <template v-else>
          <div class="elv-financials-cell-topBottom-content__top">
            {{ formatNumber(props.params?.data?.creditFC, 2) }}
            <!-- {{ formatNumber(props.params?.data?.debitFC, 2) }} -->
          </div>
          <div class="elv-financials-cell-topBottom-content__bottom">
            {{ props.params?.data?.creditAmount < Math.pow(10, -6) ? '0' : props.params?.data?.creditAmount }}
            {{ props.params?.data?.currency?.showSymbol }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import { formatNumber, fieldValueFormat, formatNumberToSignificantDigits } from '@/lib/utils'

const { t } = useI18n()
const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const cellHeight = computed(() => {
  return props.params.node?.rowPinned === 'bottom' ? '52px' : 'auto'
})

const cellMarginTop = computed(() => {
  return props.params.node?.rowPinned === 'bottom' ? '6px' : '0px'
})

const amountInValue = computed(() => {
  return props.params?.data?.direction === 'INTERNAL' || props.params?.data?.direction === 'IN'
    ? { formatValue: formatNumber(props.params?.data?.amountFC, 2), value: props.params?.data?.amountFC }
    : { formatValue: formatNumber(props.params?.data?.amountInFC, 2), value: props.params?.data?.amountInFC }
})

const amountOutValue = computed(() => {
  return props.params?.data?.direction === 'INTERNAL' || props.params?.data?.direction === 'OUT'
    ? { formatValue: formatNumber(props.params?.data?.amountFC, 2), value: props.params?.data?.amountFC }
    : { formatValue: formatNumber(props.params?.data?.amountOutFC, 2), value: props.params?.data?.amountOutFC }
})

const amountInTokenValue = computed(() => {
  let value = '0'
  if (props.params?.data?.amountInToken !== undefined) {
    value = props.params?.data?.amountInToken < 10 ** -6 ? '0' : props.params?.data?.amountInToken
  } else {
    value = Number(props.params?.data?.amount) < 10 ** -6 ? '0' : formatNumber(Number(props.params?.data?.amount), 2)
  }
  return value
})

const amountInTokenTitleValue = computed(() => {
  const value =
    props.params?.data?.amountInToken !== undefined ? props.params?.data?.amountInToken : props.params?.data?.amount
  const currency = props.params?.data?.currency?.showSymbol
    ? props.params?.data?.currency?.showSymbol
    : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)

  return `${value} ${currency}`
})

const amountOutTokenValue = computed(() => {
  let value = '0'
  if (props.params?.data?.amountOutToken !== undefined) {
    value = props.params?.data?.amountOutToken < 10 ** -6 ? '0' : props.params?.data?.amountOutToken
  } else {
    value = Number(props.params?.data?.amount) < 10 ** -6 ? '0' : formatNumber(Number(props.params?.data?.amount), 2)
  }
  return value
})

const amountOutTokenTitleValue = computed(() => {
  const value =
    props.params?.data?.amountOutToken !== undefined ? props.params?.data?.amountOutToken : props.params?.data?.amount
  const currency = props.params?.data?.currency?.showSymbol
    ? props.params?.data?.currency?.showSymbol
    : (props.params?.data?.underlyingCurrency?.showSymbol ?? props.params?.data?.currency)

  return `${value} ${currency}`
})
</script>
<style lang="scss">
.elv-financials-cell-topBottom-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: v-bind('cellHeight');
  margin-top: v-bind('cellMarginTop');

  .elv-financials-cell-topBottom-conent {
    margin-top: 6px;
    margin-bottom: 6px;
    text-align: right;

    .elv-financials-cell-topBottom-content__top-header {
      font-family: 'Barlow';
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: $elv-theme-base-text-color;
    }

    .elv-financials-cell-topBottom-content__top {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: $elv-theme-base-text-color;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      .elv-financials-cell-date-time-main-bottom__txHash {
        font-size: 12px;
        color: #838d95;
        font-weight: normal;
      }
    }

    .elv-financials-cell-topBottom-content__bottom {
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      color: $elv-color-838d95;
    }
  }

  .elv-financials-cell-topBottom-content__bottom-color-zoom {
    color: $elv-theme-tertiary-text-color;
    zoom: 0.91;
    -moz-transform: scale(0.91);
    -moz-transform-origin: top left;
  }

  .elv-financials-cell-topBottom-main__empty {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $elv-theme-base-text-color;
    text-align: right;
  }
}
</style>
